<template>
  <div class="about">
    
  </div>
</template>
<script>
  export default {
    data: () => ({
      //
    }),
    methods: {
      //
    },
    created () {
      this.loadRoute('NotFound', {});
    },
  }
</script>